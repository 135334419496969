<template>
  <div class="mobileLed">
    <div class="imgBox">
      <img src="../../assets/mobile/product/led.png" class="img" />
    </div>
    <div class="param">
      <div class="title">集中式LED调色控制器</div>
      <div class="tit">相关参数</div>
      <div class="text">
        <div class="left">产品型号:</div>
        <div class="right">LS-6RWGB</div>
      </div>
      <div class="text">
        <div class="left">额定输入:</div>
        <div class="right">12V/24V（电压可选）</div>
      </div>
      <div class="text">
        <div class="left">调色通道:</div>
        <div class="right">RGBW（红绿蓝白） /组</div>
      </div>
      <div class="text">
        <div class="left">调色组:</div>
        <div class="right">6组</div>
      </div>
      <div class="text">
        <div class="left">额定输出:</div>
        <div class="right">30W/组</div>
      </div>
      <div class="text">
        <div class="left">工作温度:</div>
        <div class="right">0°C-40°C</div>
      </div>
      <div class="text">
        <div class="left">工作湿度:</div>
        <div class="right">0%-95%RH，无冷凝</div>
      </div>          
      <div class="text">
        <div class="left">通信协议（可选）:</div>
        <div class="right">RS-485</div>
      </div>
      <div class="text">
        <div class="left">通信协议（可选）:</div>
        <div class="right">自定义LSSN2.0</div>
      </div>
      <div class="text">
        <div class="left">工作频段（可选）:</div>
        <div class="right">433.0MHz~453.0MHz</div>
      </div>
      <div class="text">
        <div class="left">无线发射功率（可选）:</div>
        <div class="right">＜10dBm</div>
      </div>
    </div>
    <div class="paramDe">
      <div
        class="txt"
      >集中式LED调色控制器是一个无线控制的彩色灯带控制装置，可驱动最多6条RGBW灯带，支持渐变式色彩调节功能，满足客房内不同区域氛围照明需求。</div>
      <div class="data">
        <img src="../../assets/mobile/dataImg.png" class="dataImg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {}
};
</script>
<style scoped lang="less">
.mobileLed {
  background-color: #fff;
  .imgBox {
    width: 100%;
    padding-top: 0.8rem;
    padding-bottom:0.65rem;
    display: flex;
    justify-content: center;
    .img {
      width: 2.39rem;
      height: 1.92rem;
    }
  }
  .param {
    text-align: left;
    margin: 0 0.3rem;
    padding-bottom: 0.29rem;
    border-bottom: 1px solid #333;
    .title {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      color: #ff9000;
      padding-bottom: 0.2rem;
      border-bottom: 1px solid #333;
    }
    .tit {
      margin-top: 0.17rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0.6rem;
    }
    .text {
      margin-top: 0.36rem;
      display: flex;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      &:first-of-type {
        margin-top: 0;
      }
      .left {
        width: 2.5rem;
        text-align: right;
        overflow: hidden; 
        text-overflow: ellipsis; 
        -o-text-overflow: ellipsis;
        white-space:nowrap;
      }
      .right {
        margin-left: 0.59rem;
      }
    }
  }
  .paramDe {
    margin-top: 0.3rem;
    padding: 0 0.3rem;
    .txt {
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      text-align: justify;
    }
    .data {
      width: 6.45rem;
      margin-top: 0.39rem;
      padding-bottom: 0.8rem;
      display: flex;

      .dataImg {
        width: 100%;
        height: 0.18rem;
      }
    }
  }
}
</style>